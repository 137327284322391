@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?2858484');
  src: url('../font/fontello.eot?2858484#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?2858484') format('woff2'),
       url('../font/fontello.woff?2858484') format('woff'),
       url('../font/fontello.ttf?2858484') format('truetype'),
       url('../font/fontello.svg?2858484#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?2858484#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-plus:before { content: '\e800'; } /* '' */
.icon-plus-1:before { content: '\e801'; } /* '' */
.icon-accueil-n:before { content: '\e802'; } /* '' */
.icon-air-n:before { content: '\e803'; } /* '' */
.icon-alerte-n:before { content: '\e804'; } /* '' */
.icon-alerte-nombre-n:before { content: '\e805'; } /* '' */
.icon-annuler-n:before { content: '\e806'; } /* '' */
.icon-a-propos-n:before { content: '\e807'; } /* '' */
.icon-assistance-n:before { content: '\e808'; } /* '' */
.icon-cadena-login-n:before { content: '\e809'; } /* '' */
.icon-capteur-n:before { content: '\e80a'; } /* '' */
.icon-catégorie-air-n:before { content: '\e80b'; } /* '' */
.icon-catégorie-eau-n:before { content: '\e80c'; } /* '' */
.icon-catégorie-energie-n:before { content: '\e80d'; } /* '' */
.icon-cgu-n:before { content: '\e80e'; } /* '' */
.icon-chrono-n:before { content: '\e80f'; } /* '' */
.icon-co2-n:before { content: '\e810'; } /* '' */
.icon-confidentialité-n:before { content: '\e811'; } /* '' */
.icon-connexion-distance-n:before { content: '\e812'; } /* '' */
.icon-copie-de-loupe-n:before { content: '\e813'; } /* '' */
.icon-cov-n:before { content: '\e814'; } /* '' */
.icon-deconnexion-n:before { content: '\e815'; } /* '' */
.icon-editer-n:before { content: '\e816'; } /* '' */
.icon-electricité-n:before { content: '\e817'; } /* '' */
.icon-energie-n:before { content: '\e818'; } /* '' */
.icon-épingle-n:before { content: '\e819'; } /* '' */
.icon-favoris-n:before { content: '\e81a'; } /* '' */
.icon-fleche-bas-listetswipe-n:before { content: '\e81b'; } /* '' */
.icon-fleche-droite-fondblanc-n:before { content: '\e81c'; } /* '' */
.icon-fleche-droite-listetswipe-n:before { content: '\e81d'; } /* '' */
.icon-fleche-droite-n:before { content: '\e81e'; } /* '' */
.icon-fleche-gauche-fondblanc-n:before { content: '\e81f'; } /* '' */
.icon-fleche-gauche-listetswipe-n:before { content: '\e820'; } /* '' */
.icon-fleche-gauche-n:before { content: '\e821'; } /* '' */
.icon-fleche-haut-listetswipe-n:before { content: '\e822'; } /* '' */
.icon-historique-n:before { content: '\e823'; } /* '' */
.icon-humidité-n:before { content: '\e824'; } /* '' */
.icon-localisation-n:before { content: '\e825'; } /* '' */
.icon-loupe-n:before { content: '\e826'; } /* '' */
.icon-lumiere-n:before { content: '\e827'; } /* '' */
.icon-mail-n:before { content: '\e828'; } /* '' */
.icon-mouvement-n:before { content: '\e829'; } /* '' */
.icon-new-n:before { content: '\e82a'; } /* '' */
.icon-paramètre-n:before { content: '\e82b'; } /* '' */
.icon-plus-fondblanc-n:before { content: '\e82c'; } /* '' */
.icon-plus-n:before { content: '\e82d'; } /* '' */
.icon-poubelle-fond-n:before { content: '\e82e'; } /* '' */
.icon-poubelle-n:before { content: '\e82f'; } /* '' */
.icon-pression-barometrique-n:before { content: '\e830'; } /* '' */
.icon-prise-connectee-n:before { content: '\e831'; } /* '' */
.icon-retour-bas-fondblanc-n:before { content: '\e832'; } /* '' */
.icon-retour-bas-n:before { content: '\e833'; } /* '' */
.icon-retour-haut-fondblanc-n:before { content: '\e834'; } /* '' */
.icon-retour-haut-n:before { content: '\e835'; } /* '' */
.icon-sonde-mesure-n:before { content: '\e836'; } /* '' */
.icon-température-n:before { content: '\e837'; } /* '' */
.icon-triangle-alerte-n:before { content: '\e838'; } /* '' */
.icon-valider-n:before { content: '\e839'; } /* '' */
.icon-widget-installé-n:before { content: '\e83a'; } /* '' */
.icon-plus-2:before { content: '\e879'; } /* '' */
